import validate from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/pages/runtime/validate.js";
import cms_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/cms/middleware/cms.global.ts";
import affiliate_45and_45referrer_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/base/middleware/affiliate-and-referrer.global.ts";
import backend_45token_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/base/middleware/backend-token.global.ts";
import consent_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/base/middleware/consent.global.ts";
import utm_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/base/middleware/utm.global.ts";
import widget_45mode_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/layers/base/middleware/widget-mode.global.ts";
import redirects_45global from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/apps/site-factory/middleware/redirects.global.ts";
import manifest_45route_45rule from "/home/forge/deployments/sitefactory/dev/2024-11-15_11-03-37_b9782ef/node_modules/.pnpm/nuxt@3.13.0_eslint@8.57.0_typescript@5.5.4_vite@5.4.3/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cms_45global,
  affiliate_45and_45referrer_45global,
  backend_45token_45global,
  consent_45global,
  utm_45global,
  widget_45mode_45global,
  redirects_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}